import React, { useState } from 'react';
import './DataTable.css';
import Pagination from '../Pagination/Pagination';

/**
 * DataTable component is a reusable table component that renders a table with optional pagination.
 *
 * Props:
 * @param {Array} headings - Array of table column headings.
 * @param {Array} data - Array of data to be displayed in the table.
 * @param {Function} renderRow - A function that takes a data row and index, and returns JSX for rendering each row.
 * @param {Boolean} isPagination - Flag to determine whether pagination is enabled or not.
 */

const DataTable = ({
  headings,
  data = [],
  renderRow,
  isPagination,
  selectedRowId,
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const totalItems = data?.length;
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const paginatedData =
    isPagination && Array.isArray(data)
      ? data.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage)
      : data;

  return (
    <>
      <table className="data-table-container">
        <thead>
          <tr>
            {headings?.map((heading, index) => (
              <th key={index}>{heading}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {Array.isArray(paginatedData) &&
            paginatedData.map((row, index) => (
              <tr
                key={index}
                className={`data-table-row ${selectedRowId === index ? 'active' : ''
                  }`}
              >
                {renderRow(row, index)}
              </tr>
            ))}
        </tbody>
      </table>
      {isPagination && (
        <div className="table-footer-container">
          <div className="Page-of-items">
            Page {currentPage} of {totalPages} ({totalItems} items)
          </div>
          <Pagination
            totalItems={totalItems}
            itemsPerPage={itemsPerPage}
            currentPage={currentPage}
            onPageChange={handlePageChange}
          />
        </div>
      )}
    </>
  );
};

export default DataTable;
