import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import './ConfirmAccount.css';
import Button from '@shared/Button/Button';
import { confirmAccount } from '@lib/apis/auth';

const ConfirmAccount = () => {
  const { token } = useParams();
  const [response, setResponse] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const response = await confirmAccount(token);
      console.log('response', response);
      if (response.success) {
        setResponse({
          success: true,
          message: 'Account confirmed. Please log in.',
        });
      } else {
        setResponse({
          success: false,
          message: 'Error confirming account. Please try again.',
        });
      }
    };
    fetchData();
  }, [token]);

  if (response === null) {
    return (
      <main className="confirm-account-page">
        <h1>Loading...</h1>
      </main>
    );
  }

  return (
    <main className="confirm-account-page">
      <h1>{response.message}</h1>
      <Link to="/login">
        {response.success && (
          <Button className="button--primary">Log In</Button>
        )}
      </Link>
    </main>
  );
};

export default ConfirmAccount;
