import React from "react";
import emailIcon from "@assets/images/icons/email.png";
import linkeDinIcon from "@assets/images/icons/linkedin.png";
import aliMohammed from "@assets/images/team/ali-mohammad.png";
import "./ChiefExecutiveOfficer.css";

const ChiefExecutiveOfficer = () => {
  const aliMohammadInfo = {
    email: "ali@juristai.org",
    emailSubject: "Jurist AI Inquiry",
    linkedinUrl: "https://calendly.com/alisamin",
    name: "Ali Mohammed",
    title: "CEO & Technical Founder",
    description:
      "Ali Mohammed has always had a strong sense of justice and a passion for the law. He co-founded a nonprofit designed to address routine due process violations when he was only 20 years old. He went on to become an experienced Solutions Architect of  Blockchain, AWS, & Linux-based systems, a Golang/ Python/DApp  engineer, and an integrated Systems-Business Analyst.",
    secondDescription: "Ali has a Masters of Science in Information Technology Management. In addition to having extensive hands-on experience with the law, he is incredibly technical. JuristAI is his brain-child.",
    imageUrl: aliMohammed,
    quote: "We are using AI as part of a suite of tools to take over the legal industry. Our goal is to use modern tech to revive the promises of the Founding Fathers.",
  };

  return (
    <div className="team-member">
      <div className="team-member-info">
        <img src={aliMohammadInfo.imageUrl} alt={aliMohammadInfo.name} className="team-member-image" />
        <div className="team-member-details">
          <h3>{aliMohammadInfo.name}</h3>
          <h4>{aliMohammadInfo.title}</h4>
          <p>{aliMohammadInfo.description}</p>
          <p>{aliMohammadInfo.secondDescription}</p>
          <div className="social-accounts">
            <a href={`mailto:${aliMohammadInfo.email}?subject=${aliMohammadInfo.emailSubject}`} target="_blank" rel="noopener noreferrer">
              <img src={emailIcon} alt="Email Icon" />
            </a>
            <a href={aliMohammadInfo.linkedinUrl} target="_blank" rel="noopener noreferrer">
              <img src={linkeDinIcon} alt="LinkedIn Icon" />
            </a>
          </div>
          <span>{`"${aliMohammadInfo.quote}"`}</span>
        </div>
      </div>
    </div>
  );
};

export default ChiefExecutiveOfficer;
