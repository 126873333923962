import React from 'react';
import './NavBarListItem.css';
import { NavLink } from 'react-router-dom';
import NotificationBadge from '../NotificationBadge/NotificationBadge';

const NavBarListItem = ({ icon: Icon, text, url, notifications = 0 }) => {
  return (
    <li className='navbar-list-element'>
    <NavLink to={url} end  className={({ isActive }) => `nav-bar-list-item ${isActive ? 'active' : ''}`}>
      <Icon />
      <p>{text}</p>
      {notifications > 0 && <NotificationBadge count={notifications} className={"danger"} style={{position: "absolute", right: "1.25rem"}} />}
    </NavLink>
    </li>
  )
}

export default NavBarListItem;
