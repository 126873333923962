import React, { useState, useEffect } from 'react';
import './ChangePlan.css';
import Button from '@shared/Button/Button';
import { changePlanData } from './changePlanData';
import { getPricingStrategy } from '@lib/apis/resources/pricingStrategy';
import { toSentenceCase, timeStampToMDY } from '@lib/utils/strings';
import { getSubscriptionBySubId } from '@lib/apis/resources/subscription';

const ChangePlan = ({currentPlan}) => {
  const [plans, setPlans] = useState([]);


  useEffect(() => {
    const fetchPricingStrategy = async () => {
      const response = await getPricingStrategy();
      if (response.success) {
        const tiers = response.data.pricingStrategy.tiers;
        setPlans(tiers);
      }
    }
    fetchPricingStrategy();
  }, []);

  return (
    <>
       <div className="container-section">
       { currentPlan && (<div className="current-plan-container">
          {plans
            .filter((plan) => plan?.tier === currentPlan?.pricingTier)
            .map((plan) => (
              <div className="current-plan-section" key={`${currentPlan.pricingTier}-current-choice`}>
                <p>Current Plan: {`${toSentenceCase(currentPlan.pricingTier)}`}</p>
                <div className="current-plan-content">
                  <span className="key">Billing Amount</span>
                  <span className="value">{`$${currentPlan.AmountCharged}/mo`}</span>
                </div>
                <hr className="border-bottom" />
                <div className="current-plan-content">
                  <span className="key">Next Bill Date</span>
                  <span className="value">{`${timeStampToMDY(currentPlan.endDate)}`}</span>
                </div>
              </div>
            ))}
        </div>)}

        <div className="other-plans-container">
          <p className="other-plans-title"> {currentPlan ? "Other Plans" : "Plans"}</p>
          {plans
            .filter((plan) => !plan.isCurrent)
            .map((otherPlan, i) => (
              <div className="plans-card-section" key={`${otherPlan.name}${i}`}>
                <div className="plans-card-content">
                  <p>{toSentenceCase(otherPlan?.tier)}</p>
                  <p>{`$${otherPlan?.parameters.costPerUser}/mo`}</p>
                </div>
                <div className="plan-buttons">
                  <Button className="button--transparent switch-plan-button">Switch to this plan</Button>
                  <Button className="view-feature-button">View Features</Button>
                </div>
              </div>
            ))}
        </div>
      </div>
    </>
  );
};

export default ChangePlan;