import React, { useState } from "react";
import "./Dropdown.css";
import dropdownIcon from "@assets/images/icons/arrow-dropdown-black.svg";
import dropFilterIcon from "@assets/images/icons/dropdown-filter.svg";


const Dropdown = ({ options, selectedOption, onOptionSelect, placeholder = "Select...", isFilter, className = "" }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleDropdownClick = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (option) => {
    onOptionSelect(option);
    setIsOpen(false);
  };

  return (
    <div className={`dropdown-container ${className}`} onClick={handleDropdownClick}>
      <div className="dropdown-label">
        {
          isFilter && <img src={dropFilterIcon} alt="Dropdown Icon" className="dropdown-icon" />
        }
        <span className="drop-down-selected-option">{selectedOption || placeholder}</span>
      </div>
      <img src={dropdownIcon} alt="Dropdown Arrow" className={`dropdown-arrow ${isOpen ? 'open' : ''}`} />
      {isOpen && (
        <div className="dropdown--menu">
          {options.map((option, index) => (
            <div
              key={index}
              className="dropdown--item"
              onClick={() => handleOptionClick(option)}
            >
              {option}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Dropdown;
