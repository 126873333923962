import React from "react";
import twitterIcon from "@assets/images/icons/twitter.png";

const Twitter = () => {
  const twitterUrl = "#";
  return (
    <a href={twitterUrl} className="button-disabled">
      <img src={twitterIcon} alt="Twitter icon" />
    </a>
  );
};

export default Twitter;
