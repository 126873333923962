import React, { useState } from 'react';
import './AccountOption.css';
import PageTitle from '../../Shared/PageTitle/PageTitle';
import Button from '@shared/Button/Button';
import TextField from '@shared/TextField/TextField';
import Trash from '@assets/images/icons/trash-icon.svg';
import engineerTen from '@assets/images/team/engineer-ten.png';
import ArrowRight from '@assets/images/icons/black-right-arrow-icon.svg';
import Dropdown from '@shared/Dropdown/Dropdown';
import ChangePassword from './ChangePassword/ChangePassword';

const Account_Types = ['Admin', 'User'];

const AccountOption = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    alternateEmail: '',
    accountType: '',
    officeName: '',
  });
  const [selectedRole, setSelectedRole] = useState(Account_Types[0]);
  const [changePassword, setChangePassword] = useState(false);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleRoleSelect = (role) => {
    setSelectedRole(role);
  };

  return (
    <>
      {!changePassword ? (
        <>
          <h1 className="account-options-title">Account Options</h1>
          <div className="main-container-account-option">
            <div className="left-side-cards">
              <div className="account-card-containers">
                <h2>Photo Profile</h2>
                <div className="profile-content-main">
                  <img src={engineerTen} alt="upload new picture" />
                  <div className="profile-content">
                    <Button className="profile-content-upload-button">Upload New Picture</Button>
                    <img src={Trash} alt="Delete" className="account-delete-icon" />
                  </div>
                </div>
              </div>

              <div className="account-card-containers">
                <h2>Settings & Privacy</h2>
                <div className="buttons-container">
                  <Button onClick={() => setChangePassword(true)} className="setting-privacy-section-buttons">
                    Change Password{' '}
                    <span>
                      <img src={ArrowRight} alt="right-arrow" className="arrow-right-icon" />
                    </span>{' '}
                  </Button>
                  <Button className="setting-privacy-section-buttons">
                    Manage Billing{' '}
                    <span>
                      <img src={ArrowRight} alt="right-arrow" className="arrow-right-icon" />
                    </span>{' '}
                  </Button>
                </div>
              </div>
            </div>

            <div className="personal-information-container">
              <h1>Personal Information</h1>
              <div className="personal-information-section">
                <div className="input-fields-container">
                  <label htmlFor="firstName">
                    First Name
                    <TextField
                      placeholder="First Name"
                      name="firstName"
                      value={formData.firstName}
                      onChange={handleChange}
                      className="text-fields-account-sections"
                    />
                  </label>
                  <label htmlFor="lastName">
                    Last Name
                    <TextField
                      placeholder="Last Name"
                      name="lastName"
                      value={formData.lastName}
                      onChange={handleChange}
                      className="text-fields-account-sections"
                    />
                  </label>
                </div>

                <div className="input-fields-container">
                  <label htmlFor="email">
                    Email Address
                    <TextField
                      placeholder="Email Address"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      className="text-fields-account-sections"
                    />
                  </label>

                  <label htmlFor="alternateEmail">
                    Alternate Email Address
                    <TextField
                      placeholder="Alternate Email Address"
                      name="alternateEmail"
                      value={formData.alternateEmail}
                      onChange={handleChange}
                      className="text-fields-account-sections"
                    />
                  </label>
                </div>

                <div className="input-fields-container">
                  <label htmlFor="accountType">
                    Account Type
                    <Dropdown
                      options={Account_Types}
                      selectedOption={selectedRole}
                      onOptionSelect={handleRoleSelect}
                      className="dropdown-container account-type-dropdown"
                    />
                  </label>
                  <label htmlFor="officeName">
                    Office/Firm Name
                    <TextField
                      placeholder="Office Name"
                      name="officeName"
                      value={formData.officeName}
                      onChange={handleChange}
                      className="text-fields-account-sections"
                    />
                  </label>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <ChangePassword onCancel={() => setChangePassword(false)} />
      )}
    </>
  );
};

export default AccountOption;
