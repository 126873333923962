import React from "react";
import { getPricingStrategy } from "@lib/apis/resources/pricingStrategy";



const Test = () => {

  const handleOnClick = async () => {
    console.log("Button Clicked");
    const response = await getPricingStrategy();
    console.log(response);
  }

  return (
    <div>
      <h1>Testing Page</h1>
      <button onClick={handleOnClick}>Click Me</button>
    </div>
  );
}

export default Test;