import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import LogIn from '@pages/Auth/LogIn/LogIn';
import Home from '@pages/Home/Home';
import Products from '@pages/Products/Products';
import SignUp from '@pages/Auth/SignUp/SignUp';
import DashboardLayout from '@pages/Dashboard/JuristAi/Layout/Layout';
import WelcomeJurist from '@pages/Dashboard/JuristAi/Jurist/WelcomeJurist/WelcomeJurist';
import LogOut from '@pages/Auth/LogOut/LogOut';
import Layout from '@pages/Home/_Layout/Layout';
import { useSelector } from 'react-redux';
import ConfirmAccount from '@pages/Auth/ConfirmAccount/ConfirmAccount';
import ManageSubscription from '@pages/Dashboard/JuristAi/Jurist/ManageSubscription/ManageSubscription';
import ManageOrganization from '@pages/Dashboard/JuristAi/Jurist/ManageOrganization/ManageOrganization/ManageOrganization';
import AccountOption from '@pages/Dashboard/JuristAi/Jurist/AccountOption/AccountOption';
import TestPage from '@pages/Home/Test/Test';

function AllRouter() {
  const isAuthenticated = useSelector((state) => state?.user);
  const PublicRoute = ({ children }) => {
    if (isAuthenticated) {
      return <Navigate to="/dashboard" />;
    }
    return children;
  };
  return (
    <>
      <Routes>
        <Route index element={<Home />} />

        <Route path="/" element={<Layout />}>
          <Route path="logout" element={<LogOut />} />
          <Route path="register/confirm-account/:token" element={<ConfirmAccount />} />
        </Route>

        <Route
          path="/login"
          element={
            <PublicRoute>
              <LogIn />
            </PublicRoute>
          }
        />
        <Route
          path="/signup"
          element={
            <PublicRoute>
              <SignUp />
            </PublicRoute>
          }
        />

        <Route path="/products" element={<Products />} />
        <Route path="/dev_test" element={<TestPage />} />

        <Route path="/dashboard/" element={<DashboardLayout />}>
          <Route index element={<WelcomeJurist />} />
          <Route path="subscriptions" element={<ManageSubscription />} />
          <Route path="organization" element={<ManageOrganization />} />
          <Route path="account" element={<AccountOption />} />
        </Route>
      </Routes>
    </>
  );
}

export default AllRouter;
