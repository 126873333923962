import React, { useRef } from "react";
import Slider from "react-slick";
import "./VoiceOfSuccess.css";
import backquoteIcon from "@assets/images/icons/quote-left.png";
import EleanaorImage from "@assets/images/voicesOfSuccess/eleanor.svg";

const testimonials = [
  {
    quote: "JuristAI is really the first tool that’s actually using state-of-the-art technology to solve real legal problems.",
    author: "Eleanor Pena",
    company: "Fisher Phillips, LLP",
    image: EleanaorImage,
  },
  {
    quote: "JuristAI has significantly improved our legal research efficiency, saving us countless hours.",
    author: "Michael Smith",
    company: "Smith & Partners, LLC",
    image: EleanaorImage,
  },
  {
    quote: "The accuracy and speed of JuristAI have been game-changers for our firm.",
    author: "Sarah Johnson",
    company: "Johnson Law Group",
    image: EleanaorImage,
  },
];

const VoiceOfSuccess = () => {
  const sliderRef = useRef(null);

  const handleSlideClick = (event) => {
    const { clientX, currentTarget } = event;
    const targetWidth = currentTarget.offsetWidth;
    const clickPosition = clientX / targetWidth;

    if (clickPosition < 0.8) {
      sliderRef.current.slickPrev();
    } else if (clickPosition > 0.8) {
      sliderRef.current.slickNext();
    }
  };

  const settings = {
    dots: true,
    dotsToShow: 2,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    autoplay: true,
    slidesToScroll: 1,
    arrows: false,
  };

  return (
    <div className="voices-of-success-container">
      <div className="voices-of-success--container">
        <h2 className="voices-of-success-title">Satisfied voices of success</h2>
        <div className="voices-of-success-border">
          <Slider {...settings} ref={sliderRef}>
            {testimonials.map((testimonial, index) => (
              <div key={index} className="voices-of-success-slide" onClick={handleSlideClick}>
                <div className="voices-of-success-sub-container">
                  <img src={backquoteIcon} alt="quote" />
                  <div className="voices-of-success-description">
                    <p>{testimonial.quote}</p>
                    <div className="voices-of-success-author">
                      <img src={testimonial?.image} alt={testimonial.author} />
                      <div>
                        <strong>{testimonial.author}</strong>
                        <span>{testimonial.company}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default VoiceOfSuccess;
