import firstEngineer from "@assets/images/team/omer-obaid.png";
import secondEngineer from "@assets/images/team/engineer-five.png";
import thirdEngineer from "@assets/images/team/engineer-seven.png";
import fourthEngineer from "@assets/images/team/engineer-eight.png";
import fifthEngineer from "@assets/images/team/engineer-nine.png";
import sixthEngineer from "@assets/images/team/engineer-ten.png";
import seventhEngineer from "@assets/images/team/enginner-eleven.png";
import eightEngineer from "@assets/images/team/mel.png";

export const openPostionsData = [
  {
    currentTeamImages: [fourthEngineer, thirdEngineer, fifthEngineer, sixthEngineer, secondEngineer, seventhEngineer, firstEngineer, eightEngineer],
    jobTitle: "Hiring for Chief Marketing Officer",
    jobDescription:
      "We are seeking a visionary Chief Marketing Officer (CMO) with a robust background in the technology &  legal sectors. You will lead our marketing initiatives and shape our brand strategy. This role involves crafting and executing comprehensive marketing strategies, overseeing our brand identity, and ensuring alignment with business objectives to drive customer acquisition and retention. You will be working with the most cutting edge AI tools in the world and pushing them to the limit of what is possible. This role is entirely remote, but you must be based in the U.S. and authorized to work in the U.S..",
    essentials: [
      "Bachelor's degree in Marketing, Business Administration, or a related field",
      "Law Degree, preferably with time as a practicing attorney",
      "Extensive experience in leading marketing initiatives within technology & legal",
      "Proficiency in leveraging advanced AI tools and technology to create innovative marketing strategies",
      "Skilled in crafting comprehensive marketing/brand strategies",
      "Exceptional leadership, communication, and analytical skills",
    ],
  },
];
