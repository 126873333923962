import React, { useState } from 'react';
import './AddTeamMember.css';
import TextField from '@shared/TextField/TextField';
import Button from '@shared/Button/Button';
import Dropdown from '@shared/Dropdown/Dropdown';

const ROLES = ['Manager', 'Employee', 'Intern'];
const PRODUCTS = [
  'Federal Criminal',
  'Federal Civil',
  'State Civil',
  'State Criminal',
  'Tax Law',
  'Contract Law',
  'Risk',
];

const AddTeamMember = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    position: '',
    role: '',
  });
  const [selectedRole, setSelectedRole] = useState(ROLES[0]);
  const [selectedProducts, setSelectedProducts] = useState([]);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleProductClick = (product) => {
    setSelectedProducts((prevSelected) =>
      prevSelected.includes(product)
        ? prevSelected.filter((item) => item !== product)
        : [...prevSelected, product]
    );
  };

  const handleRoleSelect = (role) => {
    setSelectedRole(role);
  };

  return (
    <div className="add-team-member">
      <div className="add-team-member-input-group">
        <TextField
          placeholder="First Name"
          name="firstName"
          value={formData.firstName}
          onChange={handleChange}
        />
        <TextField
          placeholder="Last Name"
          name="lastName"
          value={formData.lastName}
          onChange={handleChange}
        />
      </div>
      <TextField
        placeholder="Member’s email"
        name="email"
        value={formData.email}
        onChange={handleChange}
      />
      <TextField
        placeholder="Member’s position"
        name="position"
        value={formData.position}
        onChange={handleChange}
      />
      <Dropdown
        options={ROLES}
        selectedOption={selectedRole}
        onOptionSelect={handleRoleSelect}
        placeholder="Select Role"
      />
      <div className="add-team-member-products">
        <label>Select Products</label>
        <div className="add-team-member-products-options">
          {PRODUCTS.map((product) => (
            <button
              key={product}
              className={`add-team-member-products-option ${
                selectedProducts.includes(product) ? 'selected' : ''
              }`}
              onClick={() => handleProductClick(product)}
            >
              {product}
            </button>
          ))}
        </div>
      </div>
      <Button className="button--primary button--full-width">
        Send invite
      </Button>
    </div>
  );
};

export default AddTeamMember;
