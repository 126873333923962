import React, { useState } from 'react';
import './AddNewPlan.css';
import CardIcon from '@assets/images/icons/card-icon.svg';
import Eps from '@assets/images/icons/eps-icon.svg';
import Giropay from '@assets/images/icons/giropay-icon.svg';
import Visa from '@assets/images/icons/visa-icon.png';
import Group from '@assets/images/icons/group-icon.svg';
import Discover from '@assets/images/icons/discover-icon.svg';
import Mastercard from '@assets/images/icons/mastercard-black-icon.svg';
import TextField from '@shared/TextField/TextField';
import Button from '@shared/Button/Button';
import Dropdown from '@shared/Dropdown/Dropdown';

const COUNTRIES = ['United States', 'United Kingdom', 'Australia', 'Germany', 'France'];

const cardHeaders = [
  { id: 1, img: CardIcon, label: 'Card' },
  { id: 2, img: Eps, label: 'EPS' },
  { id: 3, img: Giropay, label: 'Giropay' },
  { id: 4, label: '...' },
];

const AddNewPlan = () => {
  const [selectedRole, setSelectedRole] = useState(COUNTRIES[0]);
  const [activeCard, setActiveCard] = useState(0);
  const [formData, setFormData] = useState({
    cardNumber: '',
    expiry: '',
    cvc: '',
    postalCode: '',
    country: '',
  });

  const handleCardClick = (index) => {
    setActiveCard(index);
  };

  const handleRoleSelect = (country) => {
    setSelectedRole(country);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  return (
    <div className="plan-attorney-main-container">
      <div className="card-headers-container">
        {cardHeaders?.map((item, index) => (
          <div
            key={index}
            className={`single-card-section ${activeCard === index ? 'active-card' : ''}`}
            onClick={() => handleCardClick(index)}
          >
            {item.img && <img src={item.img} alt={`${item.label} image`} />}
            <p className={item.img ? 'card-text' : 'card-dots'}>{item.label}</p>
          </div>
        ))}
      </div>

      <div className="card-number-container">
        <label>
          Card number
          <div className="input-with-icons">
            <TextField
              placeholder="1234 1234 1234 1234"
              name="cardNumber"
              value={formData.cardNumber}
              onChange={handleInputChange}
              className="card-number-field"
            />

            <div className="card-number-groups-images">
              <img src={Visa} alt="Visa" className="visa-icon-small" />
              <img src={Mastercard} alt="Mastercard" />
              <img src={Group} alt="Group" />
              <img src={Discover} alt="Discover" />
            </div>
          </div>
        </label>
      </div>

      <div className="input-field-container">
        <label>
          Expiry
          <TextField
            placeholder="MM/YY"
            name="expiry"
            value={formData.expiry}
            onChange={handleInputChange}
            className="fields-entry"
          />
        </label>
        <label>
          CVC
          <TextField
            placeholder="CVC"
            name="cvc"
            value={formData.cvc}
            onChange={handleInputChange}
            className="fields-entry"
          />
        </label>
      </div>

      <div className="input-field-container">
        <label>
          Country
          <Dropdown
            options={COUNTRIES}
            selectedOption={selectedRole}
            onOptionSelect={handleRoleSelect}
            className="dropdown-container country-dropdown selected-value-options "
          />
        </label>
        <label>
          Postal code
          <TextField
            placeholder="90210"
            name="postalCode"
            value={formData.postalCode}
            onChange={handleInputChange}
            className="fields-entry"
          />
        </label>
      </div>

      <div className="subscribe-button-section">
        <Button className="subscribe-and-pay">Subscribe & Pay</Button>
      </div>
    </div>
  );
};

export default AddNewPlan;
