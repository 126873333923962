// ChangePassword.js
import React, { useState } from 'react';
import './ChangePassword.css';
import Button from '@shared/Button/Button';
import TextField from '@shared/TextField/TextField';

const ChangePassword = ({ onCancel }) => {
  const [passwordData, setPasswordData] = useState({
    oldPassword: '',
    setNewPassword: '',
    confirmNewPassword: '',
  });

  const handleChange = (e) => {
    setPasswordData({ ...passwordData, [e.target.name]: e.target.value });
  };

  return (
    <>
      <h1 className="change-password-title">Change Password</h1>
      <div className="change-password-container">
        <div className="change-password-fields">
          <label>
            Old Password
            <TextField
              placeholder="Enter Old Password"
              name="oldPassword"
              type="password"
              value={passwordData.oldPassword}
              onChange={handleChange}
              className="password-feilds-inputs"
              showToggleIcon={false}
            />
          </label>
          <label>
            Set New Password
            <TextField
              placeholder="Set New Password"
              name="setNewPassword"
              type="password"
              value={passwordData.setNewPassword}
              onChange={handleChange}
              className="password-feilds-inputs"
              showToggleIcon={false}
            />
          </label>
          <label>
            Confirm New Password
            <TextField
              placeholder="Confirm New Password"
              name="confirmNewPassword"
              type="password"
              value={passwordData.confirmNewPassword}
              onChange={handleChange}
              className="password-feilds-inputs"
              showToggleIcon={false}
            />
          </label>
        </div>
        <div className="change-password-confirm-buttons">
          <Button onClick={onCancel} className='password-cancel-button'>Cancel</Button>
          <Button className='button--primary save-changes-button'>Save Changes</Button>
        </div>
      </div>
    </>
  );
};

export default ChangePassword;
