import React from 'react';
import './DashBoard.css';
import { dashboardPanelData } from './dashboardData';
import ArrowRightGreen from '@assets/images/icons/arrow-right-green.png';
import { Link } from 'react-router-dom';
import Button from '@shared/Button/Button';

const Dashboard = () => {
  return (
    <div className="dashboard-container">
      {dashboardPanelData.map((item, index) => (
        <div
          key={index}
          className={`dashboard-card ${item.accentColor}`}
          style={{
            '--gradient-color': item.accentColor,
          }}
        >
          <div className="icon-title-container">
            <div className="icon-container">
              <img src={item.icon} alt={item.title} />
            </div>
            <div className="title-container">
              {item.comingSoon && <Button className="badge-container">Coming Soon</Button>}
              <h3 className="title">{item.title}</h3>
            </div>
          </div>
          <Link className="dashboard-pannel-link">
            <p className="bottom-text-practice-area">Select this practice area</p>
            <img src={ArrowRightGreen} alt="" />
          </Link>
        </div>
      ))}
    </div>
  );
};

export default Dashboard;
