import JuristGreen from '@assets/images/juristAIInsights/jurist-green.svg';
import JuristBlue from '@assets/images/juristAIInsights/jurist-blue.svg';
import ChapterAI from '@assets/images/juristAIInsights/chapterAI-gray.svg';
import StartUps from '@assets/images/juristAIInsights/start-ups.svg';
import JuristSky from '@assets/images/juristAIInsights/jurist-sky.svg';
import JuristPink from '@assets/images/juristAIInsights/jurist-pink.svg';
import JuristTeal from '@assets/images/juristAIInsights/jurist-teal.svg';

export const dashboardPanelData = [
    {
      title: "Atticus | Federal Criminal",
      icon: JuristGreen,
      accentColor: "linear-gradient(180deg, #7DE264 0%, #59BEB5 100%)",
    },
    {
      title: "Litig AI | Civil Litigation",
      icon: JuristBlue,
      accentColor: "linear-gradient(90deg, #173C9B 0%, #0F197A 100%)",
    },
    {
      title: "Chapter AI | Bankruptcy",
      icon: ChapterAI,
      accentColor: "linear-gradient(90deg, #2C3740 0%, #060D11 100%)",
    },
    {
      title: "Series AI | Start-Ups",
      icon: StartUps,
      accentColor: "linear-gradient(180deg, #F7DC6F 0%, #F4B942 100%)",
    },
    {
      title: "Corp AI | In-House Counsel",
      icon: JuristSky,
      comingSoon: true,
      accentColor: "linear-gradient(90deg, #85C1E9 0%, #5DADE2 100%)",
    },
    {
      title: "Intellectual Property",
      icon: JuristPink,
      comingSoon: true,
      accentColor: "linear-gradient(90deg, #FF9A9A 0%, #FF7F7F 100%)",
    },
    {
      title: "Contracts",
      icon: JuristTeal,
      comingSoon: true,
      accentColor: "linear-gradient(90deg, #A9DFBF 0%, #73C6B6 100%)",
    },
  ]