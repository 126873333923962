import React, { useState } from "react";
import TextField from "../TextField/TextField";
import Button from "../Button/Button";
import { addSubscriber } from "@lib/apis/mailChimp";
import * as toast from "@lib/toast";

const EmailSignUp = ({ buttonText, className }) => {
  const [formData, setFormData] = useState({
    email: "",
  });

  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateEmail(formData.email)) {
      try {
        const response = await addSubscriber(formData.email);
        if (!response) {
          throw new Error("Network error");
        }
        toast.success("Subscribed successfully");
        setFormData({
          ...formData,
          email: "",
        });
      } catch (error) {
        const errorMessage = "An error occurred while subscribing";
        toast.error(errorMessage);
      }
    } else {
      const errorMessage = "Invalid email address";
      toast.error(errorMessage);
    }
  };

  const handleChange = (e) => {
    const value = e.target.value;
    setFormData({
      ...formData,
      [e.target.name]: value,
    });
  };
  return (
    <form>
      <TextField onChange={handleChange} value={formData.email} placeholder={"Email address"} name="email" type="email" className={className} />
      <Button className="button--primary" type="submit" onClick={handleSubmit}>
        {buttonText ?? "Sign up for Our Newsletter"}
      </Button>
    </form>
  );
};

export default EmailSignUp;
