import guerrillaInnovation from "@assets/images/icons/innovation.png";
import empowerment from "@assets/images/icons/empowerment.png";
import impact from "@assets/images/icons/impact.png";
import transparency from "@assets/images/icons/transparency.png";
import liberty from "@assets/images/icons/liberty.png";
import justiceForAll from "@assets/images/icons/justice-for-all.png";

export const juristAIValuesData = [
  {
    title: "Guerrilla Innovation",
    description: "Our products provide something genuinely new in the market that actually make attorneys' lives easier. Otherwise, we don't ship it.",
    icon: guerrillaInnovation,
  },
  {
    title: "Empowerment",
    description: "We aim high and move fast, to vet, build, and test ideas, which is how we create the best possible legal AI products with the biggest possible impact.",
    icon: empowerment,
  },
  {
    title: "Impact",
    description: "JuristAI was founded initially to target the inequities in Criminal Justice. Our social footprint is always front & center of all we do, as mission to do no evil.",
    icon: impact,
  },
  {
    title: "Transparency",
    description: "Our technology stack, the data, application security, and architectural 'secret sauce' we deliver products are underpinned by transparency.",
    icon: transparency,
  },
  {
    title: "Liberty",
    description: "JuristAI believes that a society where more people have access to justice is compared with technology to adhering to the principles of liberty upholds the values of liberty.",
    icon: liberty,
  },
  {
    title: "Justice for All",
    description: "Justice shouldn't be something reserved for the elite few. Our tools democratize justice by empowering attorneys and the public, leveraging AI for true social good.",
    icon: justiceForAll,
  },
];
