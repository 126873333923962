import omerObaid from "@assets/images/team/omer-obaid.png";
import engineerFive from "@assets/images/team/engineer-five.png";
import engineerSeven from "@assets/images/team/engineer-seven.png";
import engineerEight from "@assets/images/team/engineer-eight.png";
import engineerNine from "@assets/images/team/engineer-nine.png";
import mel from "@assets/images/team/mel.png";

export const manageOrganizationData = [
    {
        teamMember: "Ronald Richards",
        applicationRole: "Admin",
        position: "Senior Attorney",
        practiceAreas: "Federal Criminal",
        assignedCases: 3,
        viewLink: "#",
        avatar: omerObaid,
    },
    {
        teamMember: "Andrew Baker",
        applicationRole: "Admin",
        position: "Junior Attorney",
        practiceAreas: "Federal Criminal",
        assignedCases: 2,
        viewLink: "#",
        avatar: engineerFive,
    },
    {
        teamMember: "Olivia Scott",
        applicationRole: "Member",
        position: "Senior Attorney",
        practiceAreas: "Tax Law",
        assignedCases: 9,
        viewLink: "#",
        avatar: engineerSeven,
    },
    {
        teamMember: "Joshua Morgan",
        applicationRole: "Member",
        position: "Paralegal",
        practiceAreas: "State Criminal, Risk",
        assignedCases: 1,
        viewLink: "#",
        avatar: engineerEight,
    },
    {
        teamMember: "Sophia Bennett",
        applicationRole: "Member",
        position: "Paralegal",
        practiceAreas: "Federal Civil",
        assignedCases: 3,
        viewLink: "#",
        avatar: engineerNine,
    },
    {
        teamMember: "Alexander Hughes",
        applicationRole: "Member",
        position: "Paralegal",
        practiceAreas: "Federal Criminal",
        assignedCases: 4,
        viewLink: "#",
        avatar: mel,
    },
    {
        teamMember: "Ronald Richards",
        applicationRole: "Admin",
        position: "Senior Attorney",
        practiceAreas: "Federal Criminal",
        assignedCases: 3,
        viewLink: "#",
        avatar: omerObaid,
    },
    {
        teamMember: "Andrew Baker",
        applicationRole: "Admin",
        position: "Junior Attorney",
        practiceAreas: "Federal Criminal",
        assignedCases: 2,
        viewLink: "#",
        avatar: engineerFive,
    },
    {
        teamMember: "Olivia Scott",
        applicationRole: "Member",
        position: "Senior Attorney",
        practiceAreas: "Tax Law",
        assignedCases: 9,
        viewLink: "#",
        avatar: engineerSeven,
    },
    {
        teamMember: "Joshua Morgan",
        applicationRole: "Member",
        position: "Paralegal",
        practiceAreas: "State Criminal, Risk",
        assignedCases: 1,
        viewLink: "#",
        avatar: engineerEight,
    },
    {
        teamMember: "Sophia Bennett",
        applicationRole: "Member",
        position: "Paralegal",
        practiceAreas: "Federal Civil",
        assignedCases: 3,
        viewLink: "#",
        avatar: engineerNine,
    },
    {
        teamMember: "Alexander Hughes",
        applicationRole: "Member",
        position: "Paralegal",
        practiceAreas: "Federal Criminal",
        assignedCases: 4,
        viewLink: "#",
        avatar: mel,
    },
    {
        teamMember: "Ronald Richards",
        applicationRole: "Admin",
        position: "Senior Attorney",
        practiceAreas: "Federal Criminal",
        assignedCases: 3,
        viewLink: "#",
        avatar: omerObaid,
    },
    {
        teamMember: "Andrew Baker",
        applicationRole: "Admin",
        position: "Junior Attorney",
        practiceAreas: "Federal Criminal",
        assignedCases: 2,
        viewLink: "#",
        avatar: engineerFive,
    },
    {
        teamMember: "Olivia Scott",
        applicationRole: "Member",
        position: "Senior Attorney",
        practiceAreas: "Tax Law",
        assignedCases: 9,
        viewLink: "#",
        avatar: engineerSeven,
    },
    {
        teamMember: "Joshua Morgan",
        applicationRole: "Member",
        position: "Paralegal",
        practiceAreas: "State Criminal, Risk",
        assignedCases: 1,
        viewLink: "#",
        avatar: engineerEight,
    },
    {
        teamMember: "Sophia Bennett",
        applicationRole: "Member",
        position: "Paralegal",
        practiceAreas: "Federal Civil",
        assignedCases: 3,
        viewLink: "#",
        avatar: engineerNine,
    },
    {
        teamMember: "Alexander Hughes",
        applicationRole: "Member",
        position: "Paralegal",
        practiceAreas: "Federal Criminal",
        assignedCases: 4,
        viewLink: "#",
        avatar: mel,
    },
    {
        teamMember: "Ronald Richards",
        applicationRole: "Admin",
        position: "Senior Attorney",
        practiceAreas: "Federal Criminal",
        assignedCases: 3,
        viewLink: "#",
        avatar: omerObaid,
    },
    {
        teamMember: "Andrew Baker",
        applicationRole: "Admin",
        position: "Junior Attorney",
        practiceAreas: "Federal Criminal",
        assignedCases: 2,
        viewLink: "#",
        avatar: engineerFive,
    },
    {
        teamMember: "Olivia Scott",
        applicationRole: "Member",
        position: "Senior Attorney",
        practiceAreas: "Tax Law",
        assignedCases: 9,
        viewLink: "#",
        avatar: engineerSeven,
    },
    {
        teamMember: "Joshua Morgan",
        applicationRole: "Member",
        position: "Paralegal",
        practiceAreas: "State Criminal, Risk",
        assignedCases: 1,
        viewLink: "#",
        avatar: engineerEight,
    },
    {
        teamMember: "Sophia Bennett",
        applicationRole: "Member",
        position: "Paralegal",
        practiceAreas: "Federal Civil",
        assignedCases: 3,
        viewLink: "#",
        avatar: engineerNine,
    },
    {
        teamMember: "Alexander Hughes",
        applicationRole: "Member",
        position: "Paralegal",
        practiceAreas: "Federal Criminal",
        assignedCases: 4,
        viewLink: "#",
        avatar: mel,
    },
    {
        teamMember: "Ronald Richards",
        applicationRole: "Admin",
        position: "Senior Attorney",
        practiceAreas: "Federal Criminal",
        assignedCases: 3,
        viewLink: "#",
        avatar: omerObaid,
    },
    {
        teamMember: "Andrew Baker",
        applicationRole: "Admin",
        position: "Junior Attorney",
        practiceAreas: "Federal Criminal",
        assignedCases: 2,
        viewLink: "#",
        avatar: engineerFive,
    },
    {
        teamMember: "Olivia Scott",
        applicationRole: "Member",
        position: "Senior Attorney",
        practiceAreas: "Tax Law",
        assignedCases: 9,
        viewLink: "#",
        avatar: engineerSeven,
    },
    {
        teamMember: "Joshua Morgan",
        applicationRole: "Member",
        position: "Paralegal",
        practiceAreas: "State Criminal, Risk",
        assignedCases: 1,
        viewLink: "#",
        avatar: engineerEight,
    },
    {
        teamMember: "Sophia Bennett",
        applicationRole: "Member",
        position: "Paralegal",
        practiceAreas: "Federal Civil",
        assignedCases: 3,
        viewLink: "#",
        avatar: engineerNine,
    },
    {
        teamMember: "Alexander Hughes",
        applicationRole: "Member",
        position: "Paralegal",
        practiceAreas: "Federal Criminal",
        assignedCases: 4,
        viewLink: "#",
        avatar: mel,
    },
    {
        teamMember: "Ronald Richards",
        applicationRole: "Admin",
        position: "Senior Attorney",
        practiceAreas: "Federal Criminal",
        assignedCases: 3,
        viewLink: "#",
        avatar: omerObaid,
    },
    {
        teamMember: "Andrew Baker",
        applicationRole: "Admin",
        position: "Junior Attorney",
        practiceAreas: "Federal Criminal",
        assignedCases: 2,
        viewLink: "#",
        avatar: engineerFive,
    },
    {
        teamMember: "Olivia Scott",
        applicationRole: "Member",
        position: "Senior Attorney",
        practiceAreas: "Tax Law",
        assignedCases: 9,
        viewLink: "#",
        avatar: engineerSeven,
    },
    {
        teamMember: "Joshua Morgan",
        applicationRole: "Member",
        position: "Paralegal",
        practiceAreas: "State Criminal, Risk",
        assignedCases: 1,
        viewLink: "#",
        avatar: engineerEight,
    },
    {
        teamMember: "Sophia Bennett",
        applicationRole: "Member",
        position: "Paralegal",
        practiceAreas: "Federal Civil",
        assignedCases: 3,
        viewLink: "#",
        avatar: engineerNine,
    },
    {
        teamMember: "Alexander Hughes",
        applicationRole: "Member",
        position: "Paralegal",
        practiceAreas: "Federal Criminal",
        assignedCases: 4,
        viewLink: "#",
        avatar: mel,
    }
    ,
    {
        teamMember: "Ronald Richards",
        applicationRole: "Admin",
        position: "Senior Attorney",
        practiceAreas: "Federal Criminal",
        assignedCases: 3,
        viewLink: "#",
        avatar: omerObaid,
    },
    {
        teamMember: "Andrew Baker",
        applicationRole: "Admin",
        position: "Junior Attorney",
        practiceAreas: "Federal Criminal",
        assignedCases: 2,
        viewLink: "#",
        avatar: engineerFive,
    },
    {
        teamMember: "Olivia Scott",
        applicationRole: "Member",
        position: "Senior Attorney",
        practiceAreas: "Tax Law",
        assignedCases: 9,
        viewLink: "#",
        avatar: engineerSeven,
    },
    {
        teamMember: "Joshua Morgan",
        applicationRole: "Member",
        position: "Paralegal",
        practiceAreas: "State Criminal, Risk",
        assignedCases: 1,
        viewLink: "#",
        avatar: engineerEight,
    },
    {
        teamMember: "Sophia Bennett",
        applicationRole: "Member",
        position: "Paralegal",
        practiceAreas: "Federal Civil",
        assignedCases: 3,
        viewLink: "#",
        avatar: engineerNine,
    },
    {
        teamMember: "Alexander Hughes",
        applicationRole: "Member",
        position: "Paralegal",
        practiceAreas: "Federal Criminal",
        assignedCases: 4,
        viewLink: "#",
        avatar: mel,
    },
    {
        teamMember: "Ronald Richards",
        applicationRole: "Admin",
        position: "Senior Attorney",
        practiceAreas: "Federal Criminal",
        assignedCases: 3,
        viewLink: "#",
        avatar: omerObaid,
    },
    {
        teamMember: "Andrew Baker",
        applicationRole: "Admin",
        position: "Junior Attorney",
        practiceAreas: "Federal Criminal",
        assignedCases: 2,
        viewLink: "#",
        avatar: engineerFive,
    },
    {
        teamMember: "Olivia Scott",
        applicationRole: "Member",
        position: "Senior Attorney",
        practiceAreas: "Tax Law",
        assignedCases: 9,
        viewLink: "#",
        avatar: engineerSeven,
    },
    {
        teamMember: "Joshua Morgan",
        applicationRole: "Member",
        position: "Paralegal",
        practiceAreas: "State Criminal, Risk",
        assignedCases: 1,
        viewLink: "#",
        avatar: engineerEight,
    },
    {
        teamMember: "Sophia Bennett",
        applicationRole: "Member",
        position: "Paralegal",
        practiceAreas: "Federal Civil",
        assignedCases: 3,
        viewLink: "#",
        avatar: engineerNine,
    },
    {
        teamMember: "Alexander Hughes",
        applicationRole: "Member",
        position: "Paralegal",
        practiceAreas: "Federal Criminal",
        assignedCases: 4,
        viewLink: "#",
        avatar: mel,
    },
    {
        teamMember: "Ronald Richards",
        applicationRole: "Admin",
        position: "Senior Attorney",
        practiceAreas: "Federal Criminal",
        assignedCases: 3,
        viewLink: "#",
        avatar: omerObaid,
    },
    {
        teamMember: "Andrew Baker",
        applicationRole: "Admin",
        position: "Junior Attorney",
        practiceAreas: "Federal Criminal",
        assignedCases: 2,
        viewLink: "#",
        avatar: engineerFive,
    },
    {
        teamMember: "Olivia Scott",
        applicationRole: "Member",
        position: "Senior Attorney",
        practiceAreas: "Tax Law",
        assignedCases: 9,
        viewLink: "#",
        avatar: engineerSeven,
    },
    {
        teamMember: "Joshua Morgan",
        applicationRole: "Member",
        position: "Paralegal",
        practiceAreas: "State Criminal, Risk",
        assignedCases: 1,
        viewLink: "#",
        avatar: engineerEight,
    },
    {
        teamMember: "Sophia Bennett",
        applicationRole: "Member",
        position: "Paralegal",
        practiceAreas: "Federal Civil",
        assignedCases: 3,
        viewLink: "#",
        avatar: engineerNine,
    },
    {
        teamMember: "Alexander Hughes",
        applicationRole: "Member",
        position: "Paralegal",
        practiceAreas: "Federal Criminal",
        assignedCases: 4,
        viewLink: "#",
        avatar: mel,
    },
    {
        teamMember: "Ronald Richards",
        applicationRole: "Admin",
        position: "Senior Attorney",
        practiceAreas: "Federal Criminal",
        assignedCases: 3,
        viewLink: "#",
        avatar: omerObaid,
    },
    {
        teamMember: "Andrew Baker",
        applicationRole: "Admin",
        position: "Junior Attorney",
        practiceAreas: "Federal Criminal",
        assignedCases: 2,
        viewLink: "#",
        avatar: engineerFive,
    },
    {
        teamMember: "Olivia Scott",
        applicationRole: "Member",
        position: "Senior Attorney",
        practiceAreas: "Tax Law",
        assignedCases: 9,
        viewLink: "#",
        avatar: engineerSeven,
    },
    {
        teamMember: "Joshua Morgan",
        applicationRole: "Member",
        position: "Paralegal",
        practiceAreas: "State Criminal, Risk",
        assignedCases: 1,
        viewLink: "#",
        avatar: engineerEight,
    },
    {
        teamMember: "Sophia Bennett",
        applicationRole: "Member",
        position: "Paralegal",
        practiceAreas: "Federal Civil",
        assignedCases: 3,
        viewLink: "#",
        avatar: engineerNine,
    },
    {
        teamMember: "Alexander Hughes",
        applicationRole: "Member",
        position: "Paralegal",
        practiceAreas: "Federal Criminal",
        assignedCases: 4,
        viewLink: "#",
        avatar: mel,
    },
    {
        teamMember: "Ronald Richards",
        applicationRole: "Admin",
        position: "Senior Attorney",
        practiceAreas: "Federal Criminal",
        assignedCases: 3,
        viewLink: "#",
        avatar: omerObaid,
    },
    {
        teamMember: "Andrew Baker",
        applicationRole: "Admin",
        position: "Junior Attorney",
        practiceAreas: "Federal Criminal",
        assignedCases: 2,
        viewLink: "#",
        avatar: engineerFive,
    },
    {
        teamMember: "Olivia Scott",
        applicationRole: "Member",
        position: "Senior Attorney",
        practiceAreas: "Tax Law",
        assignedCases: 9,
        viewLink: "#",
        avatar: engineerSeven,
    },
    {
        teamMember: "Joshua Morgan",
        applicationRole: "Member",
        position: "Paralegal",
        practiceAreas: "State Criminal, Risk",
        assignedCases: 1,
        viewLink: "#",
        avatar: engineerEight,
    },
    {
        teamMember: "Sophia Bennett",
        applicationRole: "Member",
        position: "Paralegal",
        practiceAreas: "Federal Civil",
        assignedCases: 3,
        viewLink: "#",
        avatar: engineerNine,
    },
    {
        teamMember: "Alexander Hughes",
        applicationRole: "Member",
        position: "Paralegal",
        practiceAreas: "Federal Criminal",
        assignedCases: 4,
        viewLink: "#",
        avatar: mel,
    },
    {
        teamMember: "Ronald Richards",
        applicationRole: "Admin",
        position: "Senior Attorney",
        practiceAreas: "Federal Criminal",
        assignedCases: 3,
        viewLink: "#",
        avatar: omerObaid,
    },
    {
        teamMember: "Andrew Baker",
        applicationRole: "Admin",
        position: "Junior Attorney",
        practiceAreas: "Federal Criminal",
        assignedCases: 2,
        viewLink: "#",
        avatar: engineerFive,
    },
    {
        teamMember: "Olivia Scott",
        applicationRole: "Member",
        position: "Senior Attorney",
        practiceAreas: "Tax Law",
        assignedCases: 9,
        viewLink: "#",
        avatar: engineerSeven,
    },
    {
        teamMember: "Joshua Morgan",
        applicationRole: "Member",
        position: "Paralegal",
        practiceAreas: "State Criminal, Risk",
        assignedCases: 1,
        viewLink: "#",
        avatar: engineerEight,
    },
    {
        teamMember: "Sophia Bennett",
        applicationRole: "Member",
        position: "Paralegal",
        practiceAreas: "Federal Civil",
        assignedCases: 3,
        viewLink: "#",
        avatar: engineerNine,
    },
    {
        teamMember: "Alexander Hughes",
        applicationRole: "Member",
        position: "Paralegal",
        practiceAreas: "Federal Criminal",
        assignedCases: 4,
        viewLink: "#",
        avatar: mel,
    }, {
        teamMember: "Ronald Richards",
        applicationRole: "Admin",
        position: "Senior Attorney",
        practiceAreas: "Federal Criminal",
        assignedCases: 3,
        viewLink: "#",
        avatar: omerObaid,
    },
    {
        teamMember: "Andrew Baker",
        applicationRole: "Admin",
        position: "Junior Attorney",
        practiceAreas: "Federal Criminal",
        assignedCases: 2,
        viewLink: "#",
        avatar: engineerFive,
    },
    {
        teamMember: "Olivia Scott",
        applicationRole: "Member",
        position: "Senior Attorney",
        practiceAreas: "Tax Law",
        assignedCases: 9,
        viewLink: "#",
        avatar: engineerSeven,
    },
    {
        teamMember: "Joshua Morgan",
        applicationRole: "Member",
        position: "Paralegal",
        practiceAreas: "State Criminal, Risk",
        assignedCases: 1,
        viewLink: "#",
        avatar: engineerEight,
    },
    {
        teamMember: "Sophia Bennett",
        applicationRole: "Member",
        position: "Paralegal",
        practiceAreas: "Federal Civil",
        assignedCases: 3,
        viewLink: "#",
        avatar: engineerNine,
    },
    {
        teamMember: "Alexander Hughes",
        applicationRole: "Member",
        position: "Paralegal",
        practiceAreas: "Federal Criminal",
        assignedCases: 4,
        viewLink: "#",
        avatar: mel,
    },
    {
        teamMember: "Ronald Richards",
        applicationRole: "Admin",
        position: "Senior Attorney",
        practiceAreas: "Federal Criminal",
        assignedCases: 3,
        viewLink: "#",
        avatar: omerObaid,
    },
    {
        teamMember: "Andrew Baker",
        applicationRole: "Admin",
        position: "Junior Attorney",
        practiceAreas: "Federal Criminal",
        assignedCases: 2,
        viewLink: "#",
        avatar: engineerFive,
    },
    {
        teamMember: "Olivia Scott",
        applicationRole: "Member",
        position: "Senior Attorney",
        practiceAreas: "Tax Law",
        assignedCases: 9,
        viewLink: "#",
        avatar: engineerSeven,
    },
    {
        teamMember: "Joshua Morgan",
        applicationRole: "Member",
        position: "Paralegal",
        practiceAreas: "State Criminal, Risk",
        assignedCases: 1,
        viewLink: "#",
        avatar: engineerEight,
    },
    {
        teamMember: "Sophia Bennett",
        applicationRole: "Member",
        position: "Paralegal",
        practiceAreas: "Federal Civil",
        assignedCases: 3,
        viewLink: "#",
        avatar: engineerNine,
    },
    {
        teamMember: "Alexander Hughes",
        applicationRole: "Member",
        position: "Paralegal",
        practiceAreas: "Federal Criminal",
        assignedCases: 4,
        viewLink: "#",
        avatar: mel,
    },
    {
        teamMember: "Ronald Richards",
        applicationRole: "Admin",
        position: "Senior Attorney",
        practiceAreas: "Federal Criminal",
        assignedCases: 3,
        viewLink: "#",
        avatar: omerObaid,
    },
    {
        teamMember: "Andrew Baker",
        applicationRole: "Admin",
        position: "Junior Attorney",
        practiceAreas: "Federal Criminal",
        assignedCases: 2,
        viewLink: "#",
        avatar: engineerFive,
    },
    {
        teamMember: "Olivia Scott",
        applicationRole: "Member",
        position: "Senior Attorney",
        practiceAreas: "Tax Law",
        assignedCases: 9,
        viewLink: "#",
        avatar: engineerSeven,
    },
    {
        teamMember: "Joshua Morgan",
        applicationRole: "Member",
        position: "Paralegal",
        practiceAreas: "State Criminal, Risk",
        assignedCases: 1,
        viewLink: "#",
        avatar: engineerEight,
    },
    {
        teamMember: "Sophia Bennett",
        applicationRole: "Member",
        position: "Paralegal",
        practiceAreas: "Federal Civil",
        assignedCases: 3,
        viewLink: "#",
        avatar: engineerNine,
    },
    {
        teamMember: "Alexander Hughes",
        applicationRole: "Member",
        position: "Paralegal",
        practiceAreas: "Federal Criminal",
        assignedCases: 4,
        viewLink: "#",
        avatar: mel,
    },
    {
        teamMember: "Ronald Richards",
        applicationRole: "Admin",
        position: "Senior Attorney",
        practiceAreas: "Federal Criminal",
        assignedCases: 3,
        viewLink: "#",
        avatar: omerObaid,
    },
    {
        teamMember: "Andrew Baker",
        applicationRole: "Admin",
        position: "Junior Attorney",
        practiceAreas: "Federal Criminal",
        assignedCases: 2,
        viewLink: "#",
        avatar: engineerFive,
    },
    {
        teamMember: "Olivia Scott",
        applicationRole: "Member",
        position: "Senior Attorney",
        practiceAreas: "Tax Law",
        assignedCases: 9,
        viewLink: "#",
        avatar: engineerSeven,
    },
    {
        teamMember: "Joshua Morgan",
        applicationRole: "Member",
        position: "Paralegal",
        practiceAreas: "State Criminal, Risk",
        assignedCases: 1,
        viewLink: "#",
        avatar: engineerEight,
    },
    {
        teamMember: "Sophia Bennett",
        applicationRole: "Member",
        position: "Paralegal",
        practiceAreas: "Federal Civil",
        assignedCases: 3,
        viewLink: "#",
        avatar: engineerNine,
    },
    {
        teamMember: "Alexander Hughes",
        applicationRole: "Member",
        position: "Paralegal",
        practiceAreas: "Federal Criminal",
        assignedCases: 4,
        viewLink: "#",
        avatar: mel,
    },
    {
        teamMember: "Ronald Richards",
        applicationRole: "Admin",
        position: "Senior Attorney",
        practiceAreas: "Federal Criminal",
        assignedCases: 3,
        viewLink: "#",
        avatar: omerObaid,
    },
    {
        teamMember: "Andrew Baker",
        applicationRole: "Admin",
        position: "Junior Attorney",
        practiceAreas: "Federal Criminal",
        assignedCases: 2,
        viewLink: "#",
        avatar: engineerFive,
    },
    {
        teamMember: "Olivia Scott",
        applicationRole: "Member",
        position: "Senior Attorney",
        practiceAreas: "Tax Law",
        assignedCases: 9,
        viewLink: "#",
        avatar: engineerSeven,
    },
    {
        teamMember: "Joshua Morgan",
        applicationRole: "Member",
        position: "Paralegal",
        practiceAreas: "State Criminal, Risk",
        assignedCases: 1,
        viewLink: "#",
        avatar: engineerEight,
    },
    {
        teamMember: "Sophia Bennett",
        applicationRole: "Member",
        position: "Paralegal",
        practiceAreas: "Federal Civil",
        assignedCases: 3,
        viewLink: "#",
        avatar: engineerNine,
    },
    {
        teamMember: "Alexander Hughes",
        applicationRole: "Member",
        position: "Paralegal",
        practiceAreas: "Federal Criminal",
        assignedCases: 4,
        viewLink: "#",
        avatar: mel,
    },
    {
        teamMember: "Ronald Richards",
        applicationRole: "Admin",
        position: "Senior Attorney",
        practiceAreas: "Federal Criminal",
        assignedCases: 3,
        viewLink: "#",
        avatar: omerObaid,
    },
    {
        teamMember: "Andrew Baker",
        applicationRole: "Admin",
        position: "Junior Attorney",
        practiceAreas: "Federal Criminal",
        assignedCases: 2,
        viewLink: "#",
        avatar: engineerFive,
    },
    {
        teamMember: "Olivia Scott",
        applicationRole: "Member",
        position: "Senior Attorney",
        practiceAreas: "Tax Law",
        assignedCases: 9,
        viewLink: "#",
        avatar: engineerSeven,
    },
    {
        teamMember: "Joshua Morgan",
        applicationRole: "Member",
        position: "Paralegal",
        practiceAreas: "State Criminal, Risk",
        assignedCases: 1,
        viewLink: "#",
        avatar: engineerEight,
    },
    {
        teamMember: "Sophia Bennett",
        applicationRole: "Member",
        position: "Paralegal",
        practiceAreas: "Federal Civil",
        assignedCases: 3,
        viewLink: "#",
        avatar: engineerNine,
    },
    {
        teamMember: "Alexander Hughes",
        applicationRole: "Member",
        position: "Paralegal",
        practiceAreas: "Federal Criminal",
        assignedCases: 4,
        viewLink: "#",
        avatar: mel,
    },
    {
        teamMember: "Ronald Richards",
        applicationRole: "Admin",
        position: "Senior Attorney",
        practiceAreas: "Federal Criminal",
        assignedCases: 3,
        viewLink: "#",
        avatar: omerObaid,
    },
    {
        teamMember: "Andrew Baker",
        applicationRole: "Admin",
        position: "Junior Attorney",
        practiceAreas: "Federal Criminal",
        assignedCases: 2,
        viewLink: "#",
        avatar: engineerFive,
    },
    {
        teamMember: "Olivia Scott",
        applicationRole: "Member",
        position: "Senior Attorney",
        practiceAreas: "Tax Law",
        assignedCases: 9,
        viewLink: "#",
        avatar: engineerSeven,
    },
    {
        teamMember: "Joshua Morgan",
        applicationRole: "Member",
        position: "Paralegal",
        practiceAreas: "State Criminal, Risk",
        assignedCases: 1,
        viewLink: "#",
        avatar: engineerEight,
    },
    {
        teamMember: "Sophia Bennett",
        applicationRole: "Member",
        position: "Paralegal",
        practiceAreas: "Federal Civil",
        assignedCases: 3,
        viewLink: "#",
        avatar: engineerNine,
    },
    {
        teamMember: "Alexander Hughes",
        applicationRole: "Member",
        position: "Paralegal",
        practiceAreas: "Federal Criminal",
        assignedCases: 4,
        viewLink: "#",
        avatar: mel,
    },
    {
        teamMember: "Ronald Richards",
        applicationRole: "Admin",
        position: "Senior Attorney",
        practiceAreas: "Federal Criminal",
        assignedCases: 3,
        viewLink: "#",
        avatar: omerObaid,
    },
    {
        teamMember: "Andrew Baker",
        applicationRole: "Admin",
        position: "Junior Attorney",
        practiceAreas: "Federal Criminal",
        assignedCases: 2,
        viewLink: "#",
        avatar: engineerFive,
    },
    {
        teamMember: "Olivia Scott",
        applicationRole: "Member",
        position: "Senior Attorney",
        practiceAreas: "Tax Law",
        assignedCases: 9,
        viewLink: "#",
        avatar: engineerSeven,
    },
    {
        teamMember: "Joshua Morgan",
        applicationRole: "Member",
        position: "Paralegal",
        practiceAreas: "State Criminal, Risk",
        assignedCases: 1,
        viewLink: "#",
        avatar: engineerEight,
    },
    {
        teamMember: "Sophia Bennett",
        applicationRole: "Member",
        position: "Paralegal",
        practiceAreas: "Federal Civil",
        assignedCases: 3,
        viewLink: "#",
        avatar: engineerNine,
    },
    {
        teamMember: "Alexander Hughes",
        applicationRole: "Member",
        position: "Paralegal",
        practiceAreas: "Federal Criminal",
        assignedCases: 4,
        viewLink: "#",
        avatar: mel,
    },
    {
        teamMember: "Ronald Richards",
        applicationRole: "Admin",
        position: "Senior Attorney",
        practiceAreas: "Federal Criminal",
        assignedCases: 3,
        viewLink: "#",
        avatar: omerObaid,
    },
    {
        teamMember: "Andrew Baker",
        applicationRole: "Admin",
        position: "Junior Attorney",
        practiceAreas: "Federal Criminal",
        assignedCases: 2,
        viewLink: "#",
        avatar: engineerFive,
    },
    {
        teamMember: "Olivia Scott",
        applicationRole: "Member",
        position: "Senior Attorney",
        practiceAreas: "Tax Law",
        assignedCases: 9,
        viewLink: "#",
        avatar: engineerSeven,
    },
    {
        teamMember: "Joshua Morgan",
        applicationRole: "Member",
        position: "Paralegal",
        practiceAreas: "State Criminal, Risk",
        assignedCases: 1,
        viewLink: "#",
        avatar: engineerEight,
    },
    {
        teamMember: "Sophia Bennett",
        applicationRole: "Member",
        position: "Paralegal",
        practiceAreas: "Federal Civil",
        assignedCases: 3,
        viewLink: "#",
        avatar: engineerNine,
    },
    {
        teamMember: "Alexander Hughes",
        applicationRole: "Member",
        position: "Paralegal",
        practiceAreas: "Federal Criminal",
        assignedCases: 4,
        viewLink: "#",
        avatar: mel,
    },
    {
        teamMember: "Ronald Richards",
        applicationRole: "Admin",
        position: "Senior Attorney",
        practiceAreas: "Federal Criminal",
        assignedCases: 3,
        viewLink: "#",
        avatar: omerObaid,
    },
    {
        teamMember: "Andrew Baker",
        applicationRole: "Admin",
        position: "Junior Attorney",
        practiceAreas: "Federal Criminal",
        assignedCases: 2,
        viewLink: "#",
        avatar: engineerFive,
    },
    {
        teamMember: "Olivia Scott",
        applicationRole: "Member",
        position: "Senior Attorney",
        practiceAreas: "Tax Law",
        assignedCases: 9,
        viewLink: "#",
        avatar: engineerSeven,
    },
    {
        teamMember: "Joshua Morgan",
        applicationRole: "Member",
        position: "Paralegal",
        practiceAreas: "State Criminal, Risk",
        assignedCases: 1,
        viewLink: "#",
        avatar: engineerEight,
    },
    {
        teamMember: "Sophia Bennett",
        applicationRole: "Member",
        position: "Paralegal",
        practiceAreas: "Federal Civil",
        assignedCases: 3,
        viewLink: "#",
        avatar: engineerNine,
    },
    {
        teamMember: "Alexander Hughes",
        applicationRole: "Member",
        position: "Paralegal",
        practiceAreas: "Federal Criminal",
        assignedCases: 4,
        viewLink: "#",
        avatar: mel,
    },
    {
        teamMember: "Ronald Richards",
        applicationRole: "Admin",
        position: "Senior Attorney",
        practiceAreas: "Federal Criminal",
        assignedCases: 3,
        viewLink: "#",
        avatar: omerObaid,
    },
    {
        teamMember: "Andrew Baker",
        applicationRole: "Admin",
        position: "Junior Attorney",
        practiceAreas: "Federal Criminal",
        assignedCases: 2,
        viewLink: "#",
        avatar: engineerFive,
    },
    {
        teamMember: "Olivia Scott",
        applicationRole: "Member",
        position: "Senior Attorney",
        practiceAreas: "Tax Law",
        assignedCases: 9,
        viewLink: "#",
        avatar: engineerSeven,
    },
    {
        teamMember: "Joshua Morgan",
        applicationRole: "Member",
        position: "Paralegal",
        practiceAreas: "State Criminal, Risk",
        assignedCases: 1,
        viewLink: "#",
        avatar: engineerEight,
    },
    {
        teamMember: "Sophia Bennett",
        applicationRole: "Member",
        position: "Paralegal",
        practiceAreas: "Federal Civil",
        assignedCases: 3,
        viewLink: "#",
        avatar: engineerNine,
    },
    {
        teamMember: "Alexander Hughes",
        applicationRole: "Member",
        position: "Paralegal",
        practiceAreas: "Federal Criminal",
        assignedCases: 4,
        viewLink: "#",
        avatar: mel,
    },
    {
        teamMember: "Ronald Richards",
        applicationRole: "Admin",
        position: "Senior Attorney",
        practiceAreas: "Federal Criminal",
        assignedCases: 3,
        viewLink: "#",
        avatar: omerObaid,
    },
    {
        teamMember: "Andrew Baker",
        applicationRole: "Admin",
        position: "Junior Attorney",
        practiceAreas: "Federal Criminal",
        assignedCases: 2,
        viewLink: "#",
        avatar: engineerFive,
    },
    {
        teamMember: "Olivia Scott",
        applicationRole: "Member",
        position: "Senior Attorney",
        practiceAreas: "Tax Law",
        assignedCases: 9,
        viewLink: "#",
        avatar: engineerSeven,
    },
    {
        teamMember: "Joshua Morgan",
        applicationRole: "Member",
        position: "Paralegal",
        practiceAreas: "State Criminal, Risk",
        assignedCases: 1,
        viewLink: "#",
        avatar: engineerEight,
    },
    {
        teamMember: "Sophia Bennett",
        applicationRole: "Member",
        position: "Paralegal",
        practiceAreas: "Federal Civil",
        assignedCases: 3,
        viewLink: "#",
        avatar: engineerNine,
    },
    {
        teamMember: "Alexander Hughes",
        applicationRole: "Member",
        position: "Paralegal",
        practiceAreas: "Federal Criminal",
        assignedCases: 4,
        viewLink: "#",
        avatar: mel,
    },
    {
        teamMember: "Ronald Richards",
        applicationRole: "Admin",
        position: "Senior Attorney",
        practiceAreas: "Federal Criminal",
        assignedCases: 3,
        viewLink: "#",
        avatar: omerObaid,
    },
    {
        teamMember: "Andrew Baker",
        applicationRole: "Admin",
        position: "Junior Attorney",
        practiceAreas: "Federal Criminal",
        assignedCases: 2,
        viewLink: "#",
        avatar: engineerFive,
    },
    {
        teamMember: "Olivia Scott",
        applicationRole: "Member",
        position: "Senior Attorney",
        practiceAreas: "Tax Law",
        assignedCases: 9,
        viewLink: "#",
        avatar: engineerSeven,
    },
    {
        teamMember: "Joshua Morgan",
        applicationRole: "Member",
        position: "Paralegal",
        practiceAreas: "State Criminal, Risk",
        assignedCases: 1,
        viewLink: "#",
        avatar: engineerEight,
    },
    {
        teamMember: "Sophia Bennett",
        applicationRole: "Member",
        position: "Paralegal",
        practiceAreas: "Federal Civil",
        assignedCases: 3,
        viewLink: "#",
        avatar: engineerNine,
    },
    {
        teamMember: "Alexander Hughes",
        applicationRole: "Member",
        position: "Paralegal",
        practiceAreas: "Federal Criminal",
        assignedCases: 4,
        viewLink: "#",
        avatar: mel,
    },
    {
        teamMember: "Ronald Richards",
        applicationRole: "Admin",
        position: "Senior Attorney",
        practiceAreas: "Federal Criminal",
        assignedCases: 3,
        viewLink: "#",
        avatar: omerObaid,
    },
    {
        teamMember: "Andrew Baker",
        applicationRole: "Admin",
        position: "Junior Attorney",
        practiceAreas: "Federal Criminal",
        assignedCases: 2,
        viewLink: "#",
        avatar: engineerFive,
    },
    {
        teamMember: "Olivia Scott",
        applicationRole: "Member",
        position: "Senior Attorney",
        practiceAreas: "Tax Law",
        assignedCases: 9,
        viewLink: "#",
        avatar: engineerSeven,
    },
    {
        teamMember: "Joshua Morgan",
        applicationRole: "Member",
        position: "Paralegal",
        practiceAreas: "State Criminal, Risk",
        assignedCases: 1,
        viewLink: "#",
        avatar: engineerEight,
    },
    {
        teamMember: "Sophia Bennett",
        applicationRole: "Member",
        position: "Paralegal",
        practiceAreas: "Federal Civil",
        assignedCases: 3,
        viewLink: "#",
        avatar: engineerNine,
    },
    {
        teamMember: "Alexander Hughes",
        applicationRole: "Member",
        position: "Paralegal",
        practiceAreas: "Federal Criminal",
        assignedCases: 4,
        viewLink: "#",
        avatar: mel,
    },
    {
        teamMember: "Ronald Richards",
        applicationRole: "Admin",
        position: "Senior Attorney",
        practiceAreas: "Federal Criminal",
        assignedCases: 3,
        viewLink: "#",
        avatar: omerObaid,
    },
    {
        teamMember: "Andrew Baker",
        applicationRole: "Admin",
        position: "Junior Attorney",
        practiceAreas: "Federal Criminal",
        assignedCases: 2,
        viewLink: "#",
        avatar: engineerFive,
    },
    {
        teamMember: "Olivia Scott",
        applicationRole: "Member",
        position: "Senior Attorney",
        practiceAreas: "Tax Law",
        assignedCases: 9,
        viewLink: "#",
        avatar: engineerSeven,
    },
    {
        teamMember: "Joshua Morgan",
        applicationRole: "Member",
        position: "Paralegal",
        practiceAreas: "State Criminal, Risk",
        assignedCases: 1,
        viewLink: "#",
        avatar: engineerEight,
    },
    {
        teamMember: "Sophia Bennett",
        applicationRole: "Member",
        position: "Paralegal",
        practiceAreas: "Federal Civil",
        assignedCases: 3,
        viewLink: "#",
        avatar: engineerNine,
    },
    {
        teamMember: "Alexander Hughes",
        applicationRole: "Member",
        position: "Paralegal",
        practiceAreas: "Federal Criminal",
        assignedCases: 4,
        viewLink: "#",
        avatar: mel,
    },
    {
        teamMember: "Ronald Richards",
        applicationRole: "Admin",
        position: "Senior Attorney",
        practiceAreas: "Federal Criminal",
        assignedCases: 3,
        viewLink: "#",
        avatar: omerObaid,
    },
    {
        teamMember: "Andrew Baker",
        applicationRole: "Admin",
        position: "Junior Attorney",
        practiceAreas: "Federal Criminal",
        assignedCases: 2,
        viewLink: "#",
        avatar: engineerFive,
    },
    {
        teamMember: "Olivia Scott",
        applicationRole: "Member",
        position: "Senior Attorney",
        practiceAreas: "Tax Law",
        assignedCases: 9,
        viewLink: "#",
        avatar: engineerSeven,
    },
    {
        teamMember: "Joshua Morgan",
        applicationRole: "Member",
        position: "Paralegal",
        practiceAreas: "State Criminal, Risk",
        assignedCases: 1,
        viewLink: "#",
        avatar: engineerEight,
    },
    {
        teamMember: "Sophia Bennett",
        applicationRole: "Member",
        position: "Paralegal",
        practiceAreas: "Federal Civil",
        assignedCases: 3,
        viewLink: "#",
        avatar: engineerNine,
    },
    {
        teamMember: "Alexander Hughes",
        applicationRole: "Member",
        position: "Paralegal",
        practiceAreas: "Federal Criminal",
        assignedCases: 4,
        viewLink: "#",
        avatar: mel,
    },
    {
        teamMember: "Ronald Richards",
        applicationRole: "Admin",
        position: "Senior Attorney",
        practiceAreas: "Federal Criminal",
        assignedCases: 3,
        viewLink: "#",
        avatar: omerObaid,
    },
    {
        teamMember: "Andrew Baker",
        applicationRole: "Admin",
        position: "Junior Attorney",
        practiceAreas: "Federal Criminal",
        assignedCases: 2,
        viewLink: "#",
        avatar: engineerFive,
    },
    {
        teamMember: "Olivia Scott",
        applicationRole: "Member",
        position: "Senior Attorney",
        practiceAreas: "Tax Law",
        assignedCases: 9,
        viewLink: "#",
        avatar: engineerSeven,
    },
    {
        teamMember: "Joshua Morgan",
        applicationRole: "Member",
        position: "Paralegal",
        practiceAreas: "State Criminal, Risk",
        assignedCases: 1,
        viewLink: "#",
        avatar: engineerEight,
    },
    {
        teamMember: "Sophia Bennett",
        applicationRole: "Member",
        position: "Paralegal",
        practiceAreas: "Federal Civil",
        assignedCases: 3,
        viewLink: "#",
        avatar: engineerNine,
    },
    {
        teamMember: "Alexander Hughes",
        applicationRole: "Member",
        position: "Paralegal",
        practiceAreas: "Federal Criminal",
        assignedCases: 4,
        viewLink: "#",
        avatar: mel,
    },
    {
        teamMember: "Ronald Richards",
        applicationRole: "Admin",
        position: "Senior Attorney",
        practiceAreas: "Federal Criminal",
        assignedCases: 3,
        viewLink: "#",
        avatar: omerObaid,
    },
    {
        teamMember: "Andrew Baker",
        applicationRole: "Admin",
        position: "Junior Attorney",
        practiceAreas: "Federal Criminal",
        assignedCases: 2,
        viewLink: "#",
        avatar: engineerFive,
    },
    {
        teamMember: "Olivia Scott",
        applicationRole: "Member",
        position: "Senior Attorney",
        practiceAreas: "Tax Law",
        assignedCases: 9,
        viewLink: "#",
        avatar: engineerSeven,
    },
    {
        teamMember: "Joshua Morgan",
        applicationRole: "Member",
        position: "Paralegal",
        practiceAreas: "State Criminal, Risk",
        assignedCases: 1,
        viewLink: "#",
        avatar: engineerEight,
    },
    {
        teamMember: "Sophia Bennett",
        applicationRole: "Member",
        position: "Paralegal",
        practiceAreas: "Federal Civil",
        assignedCases: 3,
        viewLink: "#",
        avatar: engineerNine,
    },
    {
        teamMember: "Alexander Hughes",
        applicationRole: "Member",
        position: "Paralegal",
        practiceAreas: "Federal Criminal",
        assignedCases: 4,
        viewLink: "#",
        avatar: mel,
    },
]