import "./index.css";
import "./global.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AllRouter from "./AllRouter/AllRouter";

function App() {
  return (
    <div className="App">
      <AllRouter/>
      <ToastContainer newestOnTop={true} />
    </div>
  );
}

export default App;
