export const subscriptionDetail = () => {
  return [
    { key: 'Plan', value: 'Premium Plan' },
    { key: 'Billing Amount', value: '$999/Month' },
    { key: 'Next Bill Date', value: '21st June 2024' },
    { key: 'Plan Features', value: 'Standard Plan Features, Chatbot, Advanced Analysis Tools' },
  ];
};

export const paymentMethods = [
  {
    id: 1,
    cardType: 'Visa',
    cardNumber: '**** 1645',
    expiry: '04/2030',
    isPrimary: true,
  },
  {
    id: 2,
    cardType: 'Mastercard',
    cardNumber: '**** 2103',
    expiry: '02/2026',
    isPrimary: false,
  },
  {
    id: 3,
    cardType: 'Mastercard',
    cardNumber: '**** 2103',
    expiry: '02/2026',
    isPrimary: false,
  },
];

export const recentHistoryData = [
  {
    plan: 'Premium Plan (Monthly)',
    renewalDate: 'May 21, 2024',
    action: true,
  },
  {
    plan: 'Premium Plan (Monthly)',
    renewalDate: 'April 21, 2024',
    action: true,
  },
  {
    plan: 'Premium Plan (Monthly)',
    renewalDate: 'March 21, 2024',
    action: true,
  },
];
