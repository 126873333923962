import React from 'react';
import './OrderSummary.css';

const OrderSummary = ({ member }) => {
  return (
    <div className="order-summary-container">
      <div className="order-summary-row">
        <div>
          <h3 className="member-name">{member.teamMember}</h3>
          <p className="member-email">ronaldrichards@equitylaw.com</p>
        </div>
        <div className="member-position">
          <p>{member.position}</p>
        </div>
      </div>

      <div className="order-summary-row">
        <div className="small-firms-section">
          <h3>Small Firms</h3>
          <div className="key-value-pair">
            <span className="key">Billing Amount</span>
            <span className="value">$999/Month</span>
          </div>
          <hr className="border-bottom" />
          <div className="key-value-pair">
            <span className="key">Next Bill Date</span>
            <span className="value">21st June 2024</span>
          </div>
        </div>
      </div>

      <button className="button--primary checkout-button">Checkout</button>
    </div>
  );
};

export default OrderSummary;
