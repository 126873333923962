import React from "react";
import "./Career.css";
import Button from "@shared/Button/Button";

const Career = ({ careerData }) => {
  const teamLength = careerData.currentTeamImages.length;
  return (
    <div className="founding-engineer-section">
      <div className="team-images">
        {careerData.currentTeamImages.map((image, index) => (
          <img key={index} src={image} style={{ zIndex: teamLength - index }} alt={`Engineer ${index + 1}`} />
        ))}
      </div>
      <h3 className="team-job-title ">{careerData.jobTitle}</h3>
      <h6 className="team-job-title-description">{careerData.jobDescription}</h6>
      <div className="essentials">
        {careerData.essentials.map((item, index) => (
          <div key={index} className="essential-item">
            <i className="check-icon">&#10003;</i>
            <span>{item}</span>
          </div>
        ))}
      </div>
      <Button
        className="button--primary apply-btn-mobile"
        type="submit"
        onClick={() => {
          window.location.href = "mailto:ali@juristai.org";
        }}
      >
        Apply Here
      </Button>
    </div>
  );
};

export default Career;
