import React, { useState } from 'react';
import './ManageOrganization.css';
import { manageOrganizationData } from './manageOrganizationData';
import { Link } from 'react-router-dom';
import Trash from '@assets/images/icons/trash-icon.svg';
import searchIcon from '@assets/images/icons/search-gray-icon.svg';
import Dropdown from '@shared/Dropdown/Dropdown';
import viewIcon from '@assets/images/icons/eye-black.svg';
import Button from '@shared/Button/Button';
import DataTable from '@shared/DataTable/DataTable';
import Drawer from '@shared/Drawer/Drawer';
import AddTeamMember from '../AddTeamMember/AddTeamMember';
import OrderSummary from '../OrderSummary/OrderSummary';

const PRACTICE_AREAS = ['All Practice Areas', 'Area 1', 'Area 2', 'Area 3'];
const ROLES = ['All Roles', 'Manager', 'Employee', 'Intern'];
const FUNCTIONS = ['All Functions', 'Function 1', 'Function 2', 'Function 3'];

const ManageOrganization = () => {
  const [selectedPracticeArea, setSelectedPracticeArea] = useState(PRACTICE_AREAS[0]);
  const [selectedRole, setSelectedRole] = useState(ROLES[0]);
  const [selectedFunction, setSelectedFunction] = useState(FUNCTIONS[0]);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [openViewDrawer, setOpenViewDrawer] = useState(false);
  const [viewedMember, setViewedMember] = useState(null);

  const handlePracticeAreaSelect = (area) => {
    setSelectedPracticeArea(area);
  };

  const handleRoleSelect = (role) => {
    setSelectedRole(role);
  };

  const handleFunctionSelect = (func) => {
    setSelectedFunction(func);
  };

  const toggleDrawer = () => {
    setOpenDrawer(!openDrawer);
  };

  const toggleViewDrawer = (member) => {
    setViewedMember(member);
    setOpenViewDrawer(!openViewDrawer);
  };

  const headings = ['Team Member', 'Application Role', 'Position', 'Practice Areas', 'Assigned Case(s)', 'Action'];

  const renderRow = (member) => (
    <>
      <td className="dashboard-organization-member-info ">
        <img src={member.avatar} alt={`${member.teamMember} avatar`} className="member-info-avatar" />
        <span>{member.teamMember}</span>
      </td>
      <td>{member.applicationRole}</td>
      <td>{member.position}</td>
      <td>{member.practiceAreas}</td>
      <td>
        {member.assignedCases} (
        <Link href={member.viewLink} className="dashboard-organization-view-link">
          View
        </Link>
        )
      </td>
      <td className="table-action-icons">
        <img src={viewIcon} alt="View" className="action-icon" onClick={() => toggleViewDrawer(member)} />
        <img src={Trash} alt="Delete" className="action-icon" />
      </td>
    </>
  );

  return (
    <div className="dashboard-organization-container">
      <div className="organization-dashboard-sub-header-container">
        <h1 className="dashboard-organization-heading">Manage Organization</h1>
        <div>
          <div className="search-container">
            <div className="search-input-container">
              <img src={searchIcon} alt="Search Icon" className="search-icon" />
              <input type="text" placeholder="Search member..." className="search-input" />
            </div>
            <Dropdown
              options={PRACTICE_AREAS}
              selectedOption={selectedPracticeArea}
              onOptionSelect={handlePracticeAreaSelect}
              placeholder="Select Practice Area"
              isFilter
            />
            <Dropdown
              options={ROLES}
              selectedOption={selectedRole}
              onOptionSelect={handleRoleSelect}
              placeholder="Select Role"
              isFilter
            />
            <Dropdown
              options={FUNCTIONS}
              selectedOption={selectedFunction}
              onOptionSelect={handleFunctionSelect}
              placeholder="Select Function"
              isFilter
            />
          </div>
        </div>
      </div>
      <div className="dashboard-organization-table-container">
        <div className="dashboard-organization-table-header-container">
          <h2>Members</h2>
          <Button className="button--primary" onClick={toggleDrawer}>
            + Add Members{' '}
          </Button>
        </div>
        <DataTable headings={headings} data={manageOrganizationData} renderRow={renderRow} isPagination={true} />
      </div>
      <Drawer title={'Invite Member'} isOpen={openDrawer} onClose={toggleDrawer}>
        {' '}
        <AddTeamMember />{' '}
      </Drawer>

      <Drawer
        title={'Order Summary'}
        isOpen={openViewDrawer}
        onClose={toggleViewDrawer}
      >
      {viewedMember && <OrderSummary member={viewedMember} />}
      </Drawer>
    </div>
  );
};

export default ManageOrganization;
