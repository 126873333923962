import React from "react";
import "./Footer.css";
import juristAILogo from "@assets/images/logos/logo.png";
import EmailSignUp from "../NewsLetter/EmailSignUp";
import { Link, NavLink, useLocation } from "react-router-dom";
import classNames from "classnames";
import { footerLinksData } from "./footerLinks";
import Twitter from "../Socials/Twitter/Twitter";
import Instagram from "../Socials/Instagram/Instagram";
import Discord from "../Socials/Discord/Discord";
import Linkedin from "../Socials/Linkedin/Linkedin";

const Footer = () => {
  const location = useLocation(); 
  const token = localStorage.getItem("access");
  const footerBackgroundColor = location.pathname === "/logout" || `/register/confirm-account/${token}` ? "black" : "";

  const renderLinks = (links) =>
    links.map((link, index) => (
      <li key={index}>
        {link.url === "#" ? (
          <a href={link.url} className="footer-pages-links button-disabled">
            {link.title}
          </a>
        ) : (
          <NavLink to={link.url} className={({ isActive }) => classNames("footer-pages-links", { active: isActive })}>
            {link.title}
          </NavLink>
        )}
      </li>
    ));

  return (
    <footer className="footer" style={{ backgroundColor: footerBackgroundColor }}>
      <div className="footer-content">
        <div className="footer-section logo-section">
          <Link to={"/"} className="logo">
            <img src={juristAILogo} alt="JuristAI Logo" className="footer-logo" />
          </Link>
          <p className="footer-text">Get the latest insights from experts in the Law, Artificial Intelligence, Legal Technology, and their confluence!</p>
          <div className="newsletter">
            <EmailSignUp buttonText="Subscribe" className={"footer-text-field"} />
          </div>
          <div className="footer-social-icons">
            <Twitter />
            <Instagram />
            <Linkedin />
            <Discord />
          </div>
        </div>
        <div className="footer-section links-section">
          <div className="footer-links">
            <h3>Company</h3>
            <ul>{renderLinks(footerLinksData.companyLinks)}</ul>
          </div>
          <div className="footer-links">
            <h3>Solution</h3>
            <ul>{renderLinks(footerLinksData.solutionLinks)}</ul>
          </div>
          <div className="footer-links">
            <h3>More</h3>
            <ul>{renderLinks(footerLinksData.moreLinks)}</ul>
          </div>
        </div>
      </div>
      <div className="footer-bottom"></div>
      <p className="copy-right">&copy; {new Date().getFullYear()} JuristAI Legal Software Corporation</p>
    </footer>
  );
};

export default Footer;
