import React from 'react';
import ReactDrawer from 'react-modern-drawer';
import 'react-modern-drawer/dist/index.css';
import './Drawer.css';
import closeIcon from '@assets/images/icons/close-black-icon.svg';

const Drawer = ({ children, title, isOpen, onClose }) => {
    return (
        <ReactDrawer
            open={isOpen}
            onClose={onClose}
            direction='right'
            className='custom-drawer'
            style={{ width: "auto" }}
        >
            <div className='custom-drawer-main-container'>
                <div className='custom-drawer-heading-container'>
                    <h6>{title}</h6>
                    <img src={closeIcon} alt="Close" onClick={onClose} />
                </div>
                {children}
            </div>
        </ReactDrawer>
    );
};

export default Drawer;
