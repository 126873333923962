import React from 'react';
import './Navbar.css';
import JuristLogo from '@assets/images/juristAIInsights/jurist-logo-dashboard.png';
import NavBarListItem from '@shared/NavBarListItem/NavBarListItem';
import { ReactComponent as HomeIcon } from '@assets/images/icons/home.svg';
import { ReactComponent as SubscriptionsIcon } from '@assets/images/icons/cash.svg';
import { ReactComponent as OrganizationIcon } from '@assets/images/icons/team-small.svg';
import { ReactComponent as AccountIcon } from '@assets/images/icons/profile.svg';
import { ReactComponent as LogoutIcon } from '@assets/images/icons/logout-icon.svg';
import { Link } from 'react-router-dom';

const NavBar = () => {
  return (
    <div className="dashboard-navigation">
      <div className="dashboard-navigation-logo-container">
        <Link to={'https://juristai.org/'}>
          <img src={JuristLogo} className="dashboard-nav-logo" alt="logo" />
        </Link>
      </div>
      <ul className="dashboard-navigation-list-items">
        <NavBarListItem icon={HomeIcon} text="Home" url="/dashboard" />
        <NavBarListItem icon={SubscriptionsIcon} text="Manage Subscriptions" url="/dashboard/subscriptions/" />
        <NavBarListItem icon={OrganizationIcon} text="Manage Organization" url="/dashboard/organization/" />
        <NavBarListItem icon={AccountIcon} text="Account Options" url="/dashboard/account/" />
        <NavBarListItem icon={LogoutIcon} text="Logout" url="/logout" />
      </ul>
    </div>
  );
};

export default NavBar;
