import axios from 'axios';
import { getAuthHeaders, graphQlUrl } from '../utilities';

const DEFAULT_STRATEGY_ID = 'ps001'

export const getPricingStrategy = async (strategyId = DEFAULT_STRATEGY_ID) => {

  const query = `query MyQuery {
    pricingStrategy(pricingStrategyId: "${strategyId}") {
      pricingStrategyId
      appId
      strategyType
      tiers {
        parameters {
          costPerUser
        }
        tier
      }
    }
  }
  `
    
  try {
    const encodedQuery = encodeURIComponent(query);
    const URL = `${graphQlUrl}${encodedQuery}`;
    const response = await axios.get(URL);
    return {
      success: true,
      data: response.data.data
    }; // Return the case data

  } catch (error) {
    console.error('Error getting case:', error);
    return {
      success: false,
      message: error.message
    };
  }

}