import React from 'react'
import PageTitle from '../../Shared/PageTitle/PageTitle'
import Dashboard from '../DashboardPannel/DashBoard'

const WelcomeJurist = () => {
  return (
    <main className="dashboard-page main-page">
      <PageTitle subtitle="Select Practice Area" />
      <Dashboard/>
    </main>
  )
}

export default WelcomeJurist
