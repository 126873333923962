import axios from 'axios';
import { getAuthHeaders, graphQlUrl } from '../utilities';

const DEFAULT_USER_ID = '84083408-a051-70d1-f910-749d49645793'
const SUB_ID = 'sub_12345'

export const getSubscriptionByUserId = async (userId = DEFAULT_USER_ID) => {

  const query = `query MyQuery {
  subscriptionsByUser(userId: "${userId}") {
    subsId
    organizationId
    appId
    userId
    startDate
    endDate
    pricingStrategyId
    pricingTier
    AmountCharged
  }
}
  `
    
  try {
    const encodedQuery = encodeURIComponent(query);
    const URL = `${graphQlUrl}${encodedQuery}`;
    const response = await axios.get(URL);
    return {
      success: true,
      data: response.data.data
    }; // Return the data

  } catch (error) {
    console.error('Error getting case:', error);
    return {
      success: false,
      message: error.message
    };
  }

}

export const getSubscriptionBySubId = async (subId = SUB_ID) => {

  const query = `query MyQuery {
  subscription(subsId: "${subId}") {
    subsId
    organizationId
    appId
    userId
    startDate
    endDate
    pricingStrategyId
    pricingTier
    AmountCharged
    }
  }
  `
      
    try {
      const encodedQuery = encodeURIComponent(query);
      const URL = `${graphQlUrl}${encodedQuery}`;
      const response = await axios.get(URL);
      return {
        success: true,
        data: response.data.data
      }; // Return the data
  
    } catch (error) {
      console.error('Error getting case:', error);
      return {
        success: false,
        message: error.message
      };
    }

}



