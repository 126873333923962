import React from "react";
import "./SoftwareSuites.css";
import SoftwareImage from "@assets/images/softwareSuites/softwaresuites.png";
import Hero from "@assets/images/softwareSuites/hero-image.png";
import comingSoon from "@assets/images/softwareSuites/coming-soon.png";
import Accordions from "@shared/Accordion/Accordion";
import FeatureCarousel from "./Carousel/Carousel";

const SoftwareSuites = ({ refValue }) => {
  const suites = [
    {
      title: "Federal Criminal",
      description: "Atticus AI provides a comprehensive workflow for Federal defense attorneys including discovery analysis and full motion drafting.",
      image: SoftwareImage,
    },
    {
      title: "Start-Up Documentation",
      description: "SeriesAI provides start-up documentation from incorporation until exit or IPO.",
      image: Hero,
    },
    {
      title: "Family Law",
      description: "Coming Soon!",
      image: comingSoon,
    },
    {
      title: "Civil Litigation",
      description: "Coming Soon!",
      image: comingSoon,
    },
    {
      title: "Intellectual Property",
      description: "Coming Soon!",
      image: comingSoon,
    },
  ];

  return (
    <section className="software-suites-section">
      <h2 className="software-suites-title">Our Software Suites</h2>
      <Accordions data={suites} isButton isImage />
      <h2 className="software-suites-sub-title" ref={refValue}>
        Unlock the Power of AI for Legal Success with JuristAI
      </h2>
      <p className="software-suites-description">
        Embrace the Future of Legal Practice with JuristAI's Revolutionary AI-powered Solutions. From streamlining case management to enhancing research capabilities, our suite of SaaS products is meticulously designed to empower attorneys like never before.
      </p>

      <FeatureCarousel />
    </section>
  );
};

export default SoftwareSuites;
