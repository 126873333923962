import React from 'react';
import { Button, Dropdown } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import './AccountDropdown.css';

const AccountDropdown = ({ user }) => {
  const navigate = useNavigate();

  return (
    <div className="dashboard-header-user-image-container">
      <Dropdown className="custom-dropdown-toggle">
        <Dropdown.Toggle
          as={Button}
          variant="none"
          size="sm"
          style={{
            border: 'none',
            padding: 0,
          }}
        >
          {user?.profileImage ? (
            <img src={user?.profileImage} alt="user profile picture thumbnail" />
          ) : (
            <div className="dashboard-header-user-character-container">{user?.first_name?.charAt(0)}</div>
          )}
        </Dropdown.Toggle>

        <Dropdown.Menu>
          <Dropdown.Item onClick={() => navigate('/logout')}>Logout</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

export default AccountDropdown;
