import React, { useRef } from "react";
import "./Home.css";
import Nav from "@shared/Nav/Nav";
import HeroSection from "./HeroSection/HeroSecton";
import Recommendation from "./Recommendation/Recommendation";
import SoftwareSuites from "./SoftwareSuites/SoftwareSuites";
import VoiceOfSuccess from "./VoiceOfSuccess/VoiceOfSuccess";
import DataApplicationSection from "./DataApplication/DataApplication";
import TeamSection from "./Team/TeamSection";
import Footer from "@shared/Footer/Footer";
import JuristAIInsights from "./JuristAIInsights/JuristAIInsights";
import JuristAIValues from "./JuristAIValues/JuristAIValues.jsx";

const Home = () => {
  const recommendationRef = useRef(null);
  const softwareSuitesRef = useRef(null);
  const dataApplicationRef = useRef(null);
  document.title = "Home | JuristAI";

  return (
    <div>
      <div className="hero-section-bg-page-bg">
        <div className="hero-overlay"></div>
        <div className="hero-overlay"></div>
        <div className="content">
          <Nav />
          <HeroSection sectionRef={{ recommendationRef, softwareSuitesRef, dataApplicationRef }} />
        </div>
      </div>
      <div className="recommendations-container">
        <div className="recommendations-container-bg">
          <Recommendation refValue={recommendationRef} />
          <SoftwareSuites refValue={softwareSuitesRef} />
        </div>
      </div>
      <div className="home-voices-of-success-bg-color">
        <div className="home-voices-of-success-bg-image">
          <VoiceOfSuccess />
          <DataApplicationSection refValue={dataApplicationRef} />
          <TeamSection />
        </div>
        <JuristAIInsights />
        <JuristAIValues />
        <Footer />
      </div>
    </div>
  );
};

export default Home;
