import React from "react";
import "./JuristAIInsights.css";
import { insightsData } from "./juristAIInsightsData";
import Card from "@shared/Card/Card";

const JuristAIInsights = () => {
  return (
    <div className="insights-container">
      <h2 className="insights-container-heading">Stay Informed with JuristAI Insights</h2>
      <p className="insights-container-description">Explore Our Latest Articles and Updates on Legal Technology, AI Innovations, and Best Practices in Law</p>
      <div className="insights-grid">
        <Card data={insightsData} />
      </div>
    </div>
  );
};

export default JuristAIInsights;
